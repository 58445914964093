<template>
  <section v-if="look" class="shop-the-look large-pad">
    <div class="row">
      <div class="shop-the-look__left column small-12 large-10 xlarge-8 xxlarge-6">
        <PrismicLazyImage
          :image="look.image"
          width="750"
          height="750"
        />
      </div>
      <div class="shop-the-look__right column small-12 xxlarge-6">
        <h2 class="h5">{{ slice.primary.title | getTitle }}</h2>
        <h3 v-if="look.title" class="h1">
          <prismic-rich-text :field="look.title" />
        </h3>
        <prismic-rich-text v-if="look.description" :field="look.description" />
        <div class="shop-the-look__products">
          <div class="row">
            <div v-for="product in initialProducts" class="column small-6">
              <ProductCard
                :product="product"
                :hidePrice="true"
                :modalTitle="look.title[0].text"
                :modalProducts="allProducts"
              />
            </div>
          </div>
        </div>
        <ArrowLink v-if="allProducts.length > initialProducts.length" @click="handleClick" text="View All" />
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';

import { fetchLookByUid } from '@/prismic/queries';

import PrismicLazyImage from "@/components/prismic/PrismicLazyImage";
import ProductCard from "@/components/shop/ProductCard";
import ArrowLink from "@/components/global/ArrowLink";

export default {
  name: "ShopTheLook",

  components: {
    PrismicLazyImage,
    ProductCard,
    ArrowLink
  },

  data() {
    return {
      look: null
    }
  },

  props: {
    slice: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    }
  },

  computed: {
    allProducts() {
      return this.look.products.map(product => product.product);
    },
    initialProducts() {
      return this.allProducts.slice(0, 4);
    }
  },

  methods: {
    ...mapActions([
      'openQuickShopModal'
    ]),
    handleClick() {
      const payload = {
        activeProductUid: this.initialProducts[0]._meta.uid,
        modalTitle: this.look.title[0].text,
        modalProducts: this.allProducts
      };

      this.openQuickShopModal(payload);
    }
  },

  async fetch() {
    const lookUid = this.slice?.primary?.look?.uid;

    if (!lookUid) return null;

    try {
      const { data } = await this.$apolloProvider.defaultClient.query({
        query: fetchLookByUid,
        variables: {
          uid: lookUid
        }
      });

      const response = data.allShop_the_looks.edges;

      if(response.length) {
        this.look = response[0].node;
      }
    } catch(err) {
      console.log(err);
    }
  }
};
</script>
